<template>
  <div class="usecases">
    <UsecasesDashboardtPay />
    <UsecasestPayContactList id="contact-list-pg" />
    <UsecasestPayPaymentForm id="payment-form-pg" />
    <UsecasestPayPaymentLink id="payment-link-pg" />
    <GetStarted />
  </div>
</template>

<script>
import UsecasesDashboardtPay from "../components/UsecasesTpayComponents/UsecasesDashboardtPay.vue";
import UsecasestPayContactList from "../components/UsecasesTpayComponents/UsecasestPayContactList.vue";
import UsecasestPayPaymentForm from "../components/UsecasesTpayComponents/UsecasestPayPaymentForm.vue";
import UsecasestPayPaymentLink from "../components/UsecasesTpayComponents/UsecasestPayPaymentLink.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "Usecases",
  components: {
    UsecasesDashboardtPay,
    UsecasestPayContactList,
    UsecasestPayPaymentForm,
    UsecasestPayPaymentLink,
    GetStarted,
  },
  data() {
    return {
      title: "Tekkis Payment Solutions - Accept Payments Immediately",
      description:
        "Built for those wants to start selling and collecting payments immediately. No website building or coding skills required. For the Techies, custom APIs available.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Built for those wants to start selling and collecting payments immediately. No website building or coding skills required. For the Techies, custom APIs available.";
    },
  },
};
</script>
